<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div>
            <h4 class="d-inline"><i class="fa fa-users"></i> All Users List</h4>
          </div>
          <hr class="clearfix my-4" />
          <vue-json-to-csv :labels="labels" :csv-title="'Users List'" :json-data="wtsData">
            <button class="btn btn-blue float-right">
              Download CSV
              <i class="fa fa-download"></i>
            </button>
          </vue-json-to-csv>
          <v-client-table :columns="columns" :data="wtsData" :options="options" :theme="theme" class="no-overflow"
            ref="table" id="dataTable">
            <div v-if="isProject(props.row)" slot="toggler" slot-scope="props">
              <i class="fa fa-chevron-circle-down" aria-hidden="true" @click="toggle(props.row.id, $event)"></i>
            </div>
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status == 'Active'" class="text-success">
                <span type="text" class="text-success"> Active </span>
              </span>
              <span v-if="props.row.status == 'Inactive'" class="text-danger">
                <span type="text" class="text text-danger"> Inactive </span>
              </span>
            </div>
            <span slot="creation_date" slot-scope="props">{{
                props.row.creation_date | moment("M/D/YYYY")
            }}</span>

            <div v-if="isProject(props.row)" slot="child_row" slot-scope="props">
              <div v-if="props.row.role.toLowerCase() == 'wss'">
                <v-client-table :data="props.row.projects" :columns="columnsWSSProject" :options="optionWSSProject"
                  :theme="theme" class="no-overflow">
                  <div slot="status" slot-scope="props">
                    <span v-if="props.row.status == 0" class="text-success">
                      <span type="text" class="text-success"> Active </span>
                    </span>
                    <span v-if="props.row.status == 1" class="text-danger">
                      <span type="text" class="text text-danger">
                        Inactive
                      </span>
                    </span>
                    <span v-if="props.row.status == 2" class="text-danger">
                      <span type="text" class="text-danger">
                        Inactive
                      </span>
                    </span>
                  </div>
                </v-client-table>
              </div>
              <div v-else>
                <v-client-table :data="props.row.projects" :columns="columnsProject" :options="optionProject"
                  :theme="theme" class="no-overflow">
                  <div slot="status" slot-scope="props">
                    <span v-if="props.row.status == 0" class="text-success">
                      <span type="text" class="text-success"> Active </span>
                    </span>
                    <span v-if="props.row.status == 1" class="text-danger">
                      <span type="text" class="text text-danger">
                        Inactive
                      </span>
                    </span>
                    <span v-if="props.row.status == 2" class="text-danger">
                      <span type="text" class="text-danger">
                        Inactive
                      </span>
                    </span>
                  </div>
                </v-client-table>
              </div>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(ClientTable);

export default {
  name: "ManagerAssignWts",
  components: {
    ClientTable,
    Event,
    Multiselect,
    VueJsonToCsv,
  },
  data() {
    return {
      columnsProject: ["projectname", "status", "passlimit"],
      optionProject: {
        headings: {
          projectname: "Project Name",
          passlimit: "Authority",
        },
      },
      columnsWSSProject: ["projectname", "status"],
      optionWSSProject: {
        headings: {
          projectname: "Project Name",
        },
      },
      columns: [
        "toggler",
        "name",
        "role",
        "authority",
        "creation_date",
        "status",
      ],
      wtsData: [],
      isToggledRow: [],
      editable: [],
      options: {
        showChildRowToggler: false,
        headings: {
          toggler: " ",
          name: "Name",
          role: "Role",
          authority: "Authority",
          creation_date: "Creation date",
          status: "Status",
        },
        sortable: [
          "toggler",
          "name",
          "role",
          "authority",
          "creation_date",
          "status",
        ],
        filterable: [
          "toggler",
          "name",
          "role",
          "authority",
          "creation_date",
          "status",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      fname: "",
      lname: "",
      email: "",
      status: "",
      fax: "",
      phone: "",
      password: "",
      password2: "",
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      projectid: [],
      unique: false,
      assignerror: false,
      passlimit: 0,
      wtsAssignmentData: [],
      wtsFilteredData: [],
      editprojectid: "",
      editwtsid: "",
      editstatus: "",
      editpasslimit: "",
      edit: "",
      editid: "",
      editassignerror: false,
      filteredProjectData: [],
    };
  },
  methods: {
    toggle(id, event) {
      console.log(this.editable);
      const upIcon = "fa fa-chevron-circle-up";
      const downIcon = "fa fa-chevron-circle-down";
      if (!this.isToggledRow[id]) {
        event.target.className = upIcon;
        this.isToggledRow[id] = true;
        this.$refs.table.toggleChildRow(id);
      } else {
        event.target.className = downIcon;
        this.isToggledRow[id] = false;
        this.$refs.table.toggleChildRow(id);
      }
    },
    isProject(row) {
      return row.projects && row.projects.length ? true : false;
    },
    loadData() {
      this.$http
        .get("/manager/get/all_users")
        .then((response) => {
          let data = response.data.finalResponse;
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].role.toLowerCase() == "wss" &&
              data[i].projects.length
            ) {
              let isNotActive = "Inactive";
              for (let j = 0; j < data[i].projects.length; j++) {
                if (data[i].projects[j].status == 0) {
                  console.log("Founded", data[i].name);
                  isNotActive = "Active";
                }
              }
              data[i].status = isNotActive;
            }
          }
          this.wtsData = data;

          this.isToggledRow = new Array(this.wtsData.length).fill(false);
          this.wtsData.map((data) => {
            if (data.role == "Project Manager") {
              data.projects.map((project) => {
                this.editable.push({ id: project.wpid, value: false });
              });
            }
          });
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style lang="scss">
.fa-chevron-circle-up {
  font-size: 20px;
  color: red;
}

.fa-chevron-circle-down {
  font-size: 20px;
  color: green;
}

#VueTables_th--toggler {
  width: 3%;
}

label {
  display: block !important;
}
</style>
