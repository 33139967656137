var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("div", [
                    _c("h4", { staticClass: "d-inline" }, [
                      _c("i", { staticClass: "fa fa-users" }),
                      _vm._v(" All Users List")
                    ])
                  ]),
                  _c("hr", { staticClass: "clearfix my-4" }),
                  _c(
                    "vue-json-to-csv",
                    {
                      attrs: {
                        labels: _vm.labels,
                        "csv-title": "Users List",
                        "json-data": _vm.wtsData
                      }
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "btn btn-blue float-right" },
                        [
                          _vm._v("\n            Download CSV\n            "),
                          _c("i", { staticClass: "fa fa-download" })
                        ]
                      )
                    ]
                  ),
                  _c("v-client-table", {
                    ref: "table",
                    staticClass: "no-overflow",
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.wtsData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toggler",
                          fn: function(props) {
                            return _vm.isProject(props.row)
                              ? _c("div", {}, [
                                  _c("i", {
                                    staticClass: "fa fa-chevron-circle-down",
                                    attrs: { "aria-hidden": "true" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggle(props.row.id, $event)
                                      }
                                    }
                                  })
                                ])
                              : _vm._e()
                          }
                        },
                        {
                          key: "status",
                          fn: function(props) {
                            return _c("div", {}, [
                              props.row.status == "Active"
                                ? _c("span", { staticClass: "text-success" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-success",
                                        attrs: { type: "text" }
                                      },
                                      [_vm._v(" Active ")]
                                    )
                                  ])
                                : _vm._e(),
                              props.row.status == "Inactive"
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text text-danger",
                                        attrs: { type: "text" }
                                      },
                                      [_vm._v(" Inactive ")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          }
                        },
                        {
                          key: "creation_date",
                          fn: function(props) {
                            return _c("span", {}, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    props.row.creation_date,
                                    "M/D/YYYY"
                                  )
                                )
                              )
                            ])
                          }
                        },
                        {
                          key: "child_row",
                          fn: function(props) {
                            return _vm.isProject(props.row)
                              ? _c("div", {}, [
                                  props.row.role.toLowerCase() == "wss"
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-client-table", {
                                            staticClass: "no-overflow",
                                            attrs: {
                                              data: props.row.projects,
                                              columns: _vm.columnsWSSProject,
                                              options: _vm.optionWSSProject,
                                              theme: _vm.theme
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "status",
                                                  fn: function(props) {
                                                    return _c("div", {}, [
                                                      props.row.status == 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-success"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-success",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Active "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row.status == 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text text-danger",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      Inactive\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row.status == 2
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      Inactive\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c("v-client-table", {
                                            staticClass: "no-overflow",
                                            attrs: {
                                              data: props.row.projects,
                                              columns: _vm.columnsProject,
                                              options: _vm.optionProject,
                                              theme: _vm.theme
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "status",
                                                  fn: function(props) {
                                                    return _c("div", {}, [
                                                      props.row.status == 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-success"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-success",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Active "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row.status == 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text text-danger",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      Inactive\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.row.status == 2
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                  attrs: {
                                                                    type: "text"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      Inactive\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                ])
                              : _vm._e()
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }